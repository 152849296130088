import React, { useContext } from 'react';
import { Grid2 } from '@mui/material';
import AssistWalkerIcon from '@mui/icons-material/AssistWalker';
import { QueryStats } from '@mui/icons-material';
import RecommendIcon from '@mui/icons-material/Recommend';
import GradingIcon from '@mui/icons-material/Grading';
import SaveIcon from '@mui/icons-material/Save';
import { MenuButton } from '../menu-button';
import { SuperAdminButton } from '../first-bar/buttons/super-admin-button';
import { UserManagementButton } from '../first-bar/buttons/user-management-button';
import { ScrapeManagementButton } from '../first-bar/buttons/scrape-management-button';
import { ChristmasButton } from './christmas-button';
import NoCrashIcon from '@mui/icons-material/NoCrash';
import { UserContext } from '../../../user-context';

export const SecondBar = ({ internal }: { internal: boolean }) => {
  const { user } = useContext(UserContext);

  return (
    <Grid2 height={40} gap="10px" display="flex" flexDirection="row" alignSelf="flex-end">
      <>
        <ChristmasButton />
        <ScrapeManagementButton />
        <SuperAdminButton internal={internal} />
        <UserManagementButton internal={internal} />
        <MenuButton hoverTitle={'SAVED_TAXATIONS'} link="/taxations" internal={internal}>
          <SaveIcon />
        </MenuButton>
        <MenuButton
          display={user.notifications.includes('super_admin_master_mode')}
          hoverTitle={'JP.b2b'}
          link="/market/b2b?show_price_exex=true"
        >
          <NoCrashIcon />
        </MenuButton>
        <MenuButton hoverTitle={'JP.market'} link="/market/charts">
          <QueryStats />
        </MenuButton>
        <MenuButton hoverTitle={'JP.recommendations'} link="/market/recommendations">
          <RecommendIcon />
        </MenuButton>
        <MenuButton hoverTitle={'JP.management report'} link="/market/report">
          <GradingIcon />
        </MenuButton>
        <MenuButton hoverTitle={'JP.slow movers'} link="/market/slow-movers">
          <AssistWalkerIcon />
        </MenuButton>
      </>
    </Grid2>
  );
};
