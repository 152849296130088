import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Monserrat500Font } from '../../../style/fonts/fonts';
import { LocalStorageKeys } from '../../../types';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height: 600,
  boxShadow: 24,
  backgroundImage: `url('https://images.unsplash.com/photo-1482003297000-b7663a1673f1')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const ChristmasButton = () => {
  const showChristmas = localStorage.getItem(LocalStorageKeys.SHOW_CHRISTMAS_POP_UP);
  const snowSnow = localStorage.getItem(LocalStorageKeys.SNOW);

  const [open, setOpen] = useState(showChristmas === null && snowSnow === 'let_it_snow');
  const handleClose = () => {
    setOpen(false);
    localStorage.setItem(LocalStorageKeys.SHOW_CHRISTMAS_POP_UP, 'False');
  };

  return (
    <div>
      <Modal disableAutoFocus={true} open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography color={'#fff'} sx={{ fontFamily: Monserrat500Font, fontSize: '50px', textAlign: 'center' }}>
            Maak er een mooie decembermaand van!
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};
