export const Monserrat400Font = {
  fontFamily: 'montserrat',
  fontStyle: 'normal',
  fontDisplay: 'swap' as const,
  fontWeight: 400,
  src: `
    url("/fonts/montserrat-regular-webfont.woff") format('woff')
  `,
};

export const Monserrat500Font = {
  fontFamily: 'montserrat',
  fontStyle: 'normal',
  fontDisplay: 'swap' as const,
  fontWeight: 500,
  src: `
    url("/fonts/montserrat-semibold-webfont.woff") format('woff')
  `,
};
