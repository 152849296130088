import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { Theme } from './theme-material-ui';
import { LocalStorageKeys } from '../types/local-storage-keys';
import { ThemeMode, ChristmasMode } from './types';
import { StyledEngineProvider, ThemeProvider, keyframes, styled } from '@mui/system';
import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const fall = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: 85vh;
    opacity: 1;
  }
`;

const sway = keyframes`
  0% {
    margin-left: 0;
  }
  25% {
    margin-left: 50px;
  }
  50% {
    margin-left: -50px;
  }
  75% {
    margin-left: 50px;
  }
  100% {
    margin-left: 0;
  }
`;

const Snowflake = styled('div')({
  position: 'absolute',
  color: 'skyblue',
  fontFamily: "'Pacifico', cursive",
  animation: `${fall} ease-in infinite, ${sway} ease-in-out infinite`,
  zIndex: 1000000,
});

export const MuiWrapper = ({ children }: PropsWithChildren) => {
  const localTheme = localStorage.getItem(LocalStorageKeys.THEME) as ThemeMode;
  if (localTheme !== ThemeMode.LIGHT && localTheme !== ThemeMode.DARK) {
    localStorage.setItem(LocalStorageKeys.THEME, ThemeMode.DARK);
  }
  const theme = Theme(localTheme ?? ThemeMode.DARK);

  const showSnow = localStorage.getItem(LocalStorageKeys.SNOW);
  if (showSnow !== ChristmasMode.LET_IT_SNOW && showSnow !== ChristmasMode.STOP_SNOWING) {
    localStorage.setItem(LocalStorageKeys.SNOW, ChristmasMode.STOP_SNOWING);
  }
  const snowContainer = document.getElementById('snow-container');
  const snowContent = useMemo(() => ['❄️', '❅', '❆'], []);
  const [snowflakes, setSnowflakes] = useState<JSX.Element[]>([]);

  const random = (num: number) => {
    return Math.floor(Math.random() * num);
  };

  const createSnow = useCallback(
    (num: number) => {
      const newSnowflakes: JSX.Element[] = [];
      for (let i = 0; i < num; i++) {
        newSnowflakes.push(
          <Snowflake
            key={i}
            style={{
              top: `-${random(100)}%`,
              left: `${random(95)}%`,
              fontSize: `${random(12) + 12}px`,
              animationDuration: `${random(10) + 10}s`,
            }}
          >
            {snowContent[random(3)]}
          </Snowflake>,
        );
      }
      setSnowflakes(newSnowflakes);
    },
    [snowContent],
  );

  useEffect(() => {
    if (showSnow === ChristmasMode.LET_IT_SNOW) {
      createSnow(30);
    }
  }, [createSnow, showSnow]);

  useEffect(() => {
    if (snowContainer && showSnow === ChristmasMode.STOP_SNOWING) {
      snowContainer.style.opacity = '0';
      setTimeout(() => {
        snowContainer.remove();
      }, 500);
    }
  }, [createSnow, showSnow, snowContainer]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <CssBaseline />
          <span id="snow-container">
            {snowflakes}
            {children}
          </span>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
