import { ActivityType, createActivity } from '@japieglobal/shared/src/api/services';
import { AppSearchParamKeys, Tooltip } from '@japieglobal/shared/src/components';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { TopDealer } from '@japieglobal/shared/src/types';
import { UserContext } from '@japieglobal/shared/src/user-context';
import { largeNumberFormatter } from '@japieglobal/shared/src/utils';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { Chip, Typography } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ColoredButtonSmall } from '../cars/results/result-table-styles';
import { ResultTurnoverButton } from './buttons/result-turnover-button';

const useStyles = makeStyles({
  odd: {},
  even: {
    backgroundColor: 'rgb(60 217 83 / 16%)',
  },
});

const StyledButton = styled(ColoredButtonSmall)({
  height: '30px',
  width: '40px',
});

interface TopDealerButtonProps {
  dealers?: TopDealer[];
  click?: (dealer?: string) => void;
}
const TopDealerButton = ({ dealers, click }: TopDealerButtonProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { snackbarErrorHandler } = useSnackbarErrorHandler();

  const columns: GridColDef[] = [
    { field: 'dealer_name', headerName: 'Dealer', width: 200 },
    {
      field: 'stat_turnover_int',
      headerName: t('ITR'),
      width: 80,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: GridRenderCellParams) => (
        <ResultTurnoverButton
          car={{ stat: { turnover_int: params.value }, id: params.row.id }}
          searchParamKey={AppSearchParamKeys.SORT_TURNOVER_INT}
          column="itr"
          hideLabel
        />
      ),
    },
    { field: 'stock_count', headerName: t('STOCK'), width: 80, align: 'center', headerAlign: 'center' },
    { field: 'sold_count', headerName: t('SOLD'), width: 80, align: 'center', headerAlign: 'center' },
    {
      field: 'price_local',
      valueFormatter: (value) => largeNumberFormatter(user, value, 3, true),
      headerName: t('PRICE'),
      width: 80,
      align: 'center',
      headerAlign: 'center',
    },
  ];

  const registerActivity = useCallback(() => {
    createActivity({ activity: ActivityType.VIEW_TOP_DEALERS }).catch(snackbarErrorHandler);
  }, [snackbarErrorHandler]);

  return (
    <Tooltip
      interactive={true}
      closeOnClick
      onClickContent={
        <div style={{ height: 350, width: 550 }}>
          <Typography style={{ fontSize: 14, fontWeight: 700, marginBottom: 10 }}>{t('TOP_DEALERS')}</Typography>
          <DataGrid
            sx={{
              '& .MuiDataGrid-row:hover': {
                cursor: 'pointer',
                backgroundColor: 'rgb(60 217 83 / 50%)',
              },
              'height': 320,
            }}
            getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? classes.even : classes.odd)}
            rows={dealers || []}
            columns={columns}
            getRowId={(r) => r.dealer_name}
            autoPageSize
            onRowSelectionModelChange={(dealers) => {
              click ? click(dealers[0] as string) : undefined;
            }}
          />
        </div>
      }
    >
      <StyledButton disabled={!dealers?.length} title={t('TOP_DEALERS')} onClick={registerActivity}>
        <EmojiEventsIcon />
      </StyledButton>
    </Tooltip>
  );
};

interface ResultTopDealersProps {
  dealers?: TopDealer[];
  click?: (dealer?: string) => void;
  selected?: string;
}
export const ResultTopDealers = ({ dealers, click, selected }: ResultTopDealersProps) =>
  selected ? (
    <Chip label={selected} variant="outlined" color="primary" onDelete={() => (click ? click(undefined) : undefined)} />
  ) : (
    <TopDealerButton dealers={dealers} click={click} />
  );
