import { Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import { CarWindowResult } from '@japieglobal/shared/src/api/services';
import { ResultCountry } from './result-other-country';

interface ResultOtherCountriesProps {
  windowResults: { [country: string]: CarWindowResult | undefined };
  windowIsLoading: { [country: string]: boolean };
  setSelectedCountry: (country: string) => void;
}

export const ResultOtherCountries = ({
  windowIsLoading,
  windowResults,
  setSelectedCountry,
}: ResultOtherCountriesProps) => {
  const keys = Object.keys(windowResults);
  const [value, setValue] = useState<number>(0);

  const handleChange = (event, newValue: number) => {
    setValue(newValue);
    setSelectedCountry(keys[newValue]);
  };

  return (
    <Tabs variant={'fullWidth'} value={value} onChange={handleChange} sx={{ mt: 2 }}>
      {keys.map((country) => (
        <Tab
          key={country}
          label={
            <ResultCountry
              isLoading={windowIsLoading[country]}
              country={country}
              windowResult={windowResults[country]}
            />
          }
          sx={{
            fontSize: 13,
            textTransform: 'none',
            minWidth: '25%',
            maxWidth: '25%',
            height: '100%',
            border: '1px groove',
          }}
        />
      ))}
    </Tabs>
  );
};
