import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TableColDef, BaseTable, useAppSearchParams, AppSearchParamKeys } from '@japieglobal/shared/src/components';
import { csmColumns } from './csm-report-columns';
import { CellRenderWrapper } from './table-content/cell-render-wrapper';
import {
  getCsmReport,
  superAdminList,
  SuperAdminResponseType,
  UpdateDealerAccountResponse,
  CsmReport,
  CsmReportKeys,
} from '@japieglobal/shared/src/api/services';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { HeaderFilterWrapper } from '../../shared/admin-and-sales-report/header-filter/header-filter-wrapper';

export const CsmReportTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [csmReportRows, setCsmReportRows] = useState<CsmReport[]>([]);
  const [filteredCsmReportRows, setFilteredCsmReportRows] = useState<CsmReport[]>();
  const [searchParams] = useAppSearchParams();
  const prevDataType = useRef<string>();
  const { snackbarErrorHandler } = useSnackbarErrorHandler();
  const [superAdmins, setSuperAdmins] = useState<SuperAdminResponseType[]>([]);

  const filterCsmReportRows = useCallback(
    (originalData: CsmReport[]) => {
      const searchParamsEntries = Object.entries(searchParams);
      let filteredRows = originalData;
      searchParamsEntries.forEach((entry) => {
        if (entry[0] !== AppSearchParamKeys.DATA_TYPE) {
          const column = csmColumns.find((c) => c.searchParamKey === entry[0]);
          filteredRows = filteredRows.filter((row) => {
            if (column) {
              if (typeof row[column.field] === 'object') {
                if (String(entry[1]).split(',').length > 1) {
                  return String(entry[1]).split(',').includes(String(row[column.field]?.['value'].toString()));
                }
                return row[column.field]?.['value'].toString().includes(String(entry[1]));
              }
              return row[column.field]?.toString().includes(String(entry[1]));
            }
          });
        }
      });
      setFilteredCsmReportRows(filteredRows);
    },
    [searchParams],
  );

  const getCsmReportRows = useCallback(() => {
    setIsLoading(true);
    getCsmReport({ data_type: searchParams.data_type })
      .then((res) => {
        setFilteredCsmReportRows(undefined);
        // Adding the last comment to the rows like this because it was the fasted solution for now.
        setCsmReportRows(
          res.map((row) => ({
            ...row,
            [CsmReportKeys.LAST_COMMENT_DATE]:
              row?.[CsmReportKeys.COMMENTS]?.[row[CsmReportKeys.COMMENTS]?.length - 1].created_at,
          })),
        );
        filterCsmReportRows(res);
      })
      .finally(() => setIsLoading(false));
  }, [filterCsmReportRows, searchParams.data_type]);

  useEffect(() => {
    if (searchParams.data_type && prevDataType.current !== searchParams.data_type) {
      getCsmReportRows();
    } else {
      filterCsmReportRows(csmReportRows);
    }
    prevDataType.current = searchParams.data_type;
  }, [csmReportRows, filterCsmReportRows, getCsmReportRows, searchParams.data_type]);

  useEffect(() => {
    superAdminList()
      .then((res) => setSuperAdmins(res))
      .catch(snackbarErrorHandler);
  }, [snackbarErrorHandler]);

  const onDealerUpdated = (newDealerAccount: UpdateDealerAccountResponse) => {
    setCsmReportRows((prevList) =>
      prevList.map((row) =>
        row[CsmReportKeys.DEALER] === newDealerAccount.name
          ? {
              ...row,
              [CsmReportKeys.HEALTH]: newDealerAccount.health ?? undefined,
              [CsmReportKeys.STATUS]: newDealerAccount.status ?? undefined,
              [CsmReportKeys.CSM]: newDealerAccount.csm ?? undefined,
            }
          : row,
      ),
    );
  };

  const columns: TableColDef<CsmReport>[] = csmColumns.map((column) => {
    const { field, headerName, flex = 1, type, headerDescription, hoverRender } = column;
    return {
      field,
      flex,
      headerName,
      headerDescription,
      type,
      valueGetter: (value) => (hoverRender ? value['value'] : value),
      renderCell: (params) => (
        <CellRenderWrapper
          setRowsIsLoading={setIsLoading}
          key={params.id}
          onDealerUpdated={onDealerUpdated}
          superAdmins={superAdmins}
          renderCellParams={params}
          csmColumn={column}
        />
      ),
      renderHeaderFilter: (headerFilterProps) => (
        <HeaderFilterWrapper column={column} headerFilterProps={headerFilterProps} superAdmins={superAdmins} />
      ),
    } as TableColDef<CsmReport>;
  });

  return (
    <BaseTable
      filterHeader
      isLoading={isLoading}
      rows={filteredCsmReportRows || csmReportRows}
      columns={columns}
      getRowId={(row) => row[CsmReportKeys.ACCOUNT_ID]}
      headerHeight={65}
      rowHeight={45}
      exportToPdf={false}
      pinnedRows={{
        top: [],
        bottom: [],
      }}
      showCellBorder={true}
    />
  );
};
